//@ts-nocheck
import React, { useRef } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import Rating from '/components/ui/Rating';
import Lightning from '/components/ui/Lightning';
import Price from '/components/ui/Price';
import { textOverflow } from '/style/mixin';
import formatPageview from '/utils/formatPageview';
import { connect } from 'react-redux';
import ListItemBox from '/components/list/ListItemBox';
import parse from '/utils/parse';
import TypeExperienceItem from '../../type/TypeExperienceItem';
import moment from 'moment';
import 'moment-timezone';
import Link from 'next/link';
import { transition } from '/style/mixin';
import useLazyBg from '/utils/useLazyBg';
import NumberFormat from "react-number-format";

interface Props {
  data: TypeExperienceItem;
  fixed_width?: boolean;
}
interface StyledProps {
  cover_image: string;
  has_description: boolean;
}
const Wrapper = styled.article<StyledProps>`
  position: relative;
  > a {
    display: block;
    /* height: 394px; */
    /* width: 271px; */
  }
  .description {
    height: 138px;
    margin-top: 15px;
    padding: 0 5px;
    font-size: 14px;
    line-height: 23px;
    color: ${theme.mid_gy};
    letter-spacing: -0.1px;
    @media ${theme.xs_max} {
      height: auto;
    }
  }
  .item-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 168px;
    opacity: 0;
    ${transition('opacity .2s ease-out')}
    &.on-screen {
      opacity: 1;
      background-image: url("${props => props.cover_image}");
    }
    @media ${theme.sm_max} {
      height: 180px;
    }
    svg {
      width: 12px;
      height: 10px;
    }
  }
  .item-text {
    padding: 15px 15px 0;
  }
  .item-meta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 44px;
    padding: 15px;
    border-top: 1px solid ${theme.line_gy};
    .made-by-trazy {
      vertical-align: top;
      font-size: 11px;
      line-height: 1.2;
      font-weight: ${theme.bold};
      letter-spacing: -0.1px;
      float: right;
      color: ${theme.brand};
    }
    .meta {
      display: inline-block;
      color: ${theme.mid_gy};
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: -0.2px;
      vertical-align: top;
      margin-right: 10px;
      text-transform: uppercase;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: top;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 2px;
        margin-right: 5px;
      }
      &_location {
        &:before {
          width: 8px;
          height: 10px;
          background-image: url(${require('/public/images/ui/ico-location.svg')});
        }
      }
      &_view {
        &:before {
          width: 14px;
          height: 10px;
          background-image: url(${require('/public/images/ui/ico-view.svg')});
        }
      }
    }
  }
  .price {
    position: absolute;
    bottom: 59px;
    left: 0;
    right: 0;
    padding: 0 15px;
  }
  .review {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 5px;
    color: ${theme.mid_gy};
    .rating_val {
      font-weight: ${theme.bold};
      color: ${theme.trazy_og};
      padding-right: 3px;
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 10px;
        background-image: url(${require('/public/images/ui/ico-rating_on.svg')});
        margin-right: 3px;
      }
    }
  }
  .status {
    font-size: 13px;
    font-weight: ${theme.bold};
    color: ${theme.dark_mint};
    line-height: 15px;
    margin-bottom: 5px;
  }
  .selling {
    vertical-align: top;
    line-height: 21px;
    font-size: 18px;
    font-weight: ${theme.bold};
    letter-spacing: -0.14px;
    text-align: left;
    color: ${theme.dark_gy};
  }
  .original {
    vertical-align: top;
    line-height: 21px;
    font-size: 16px;
    letter-spacing: -0.13px;
    color: ${theme.gy};
    margin-left: 10px;
    text-decoration: line-through;
  }
  .title {
    font-size: 15px;
    line-height: 1.56;
    letter-spacing: -0.21px;
    color: ${theme.mid_gy};
    font-weight: ${theme.medi};
    ${textOverflow(3, 1.56)};
    @media ${theme.sm_max} {
      /* mobile Ellipsis */
      ${textOverflow(2, 1.56)}
    }
  }
`;
const New = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  font-weight: ${theme.bold};
  color: #fff;
  text-align: center;
  border-radius: 0 5px 5px 0;
  background-image: linear-gradient(
    to left,
    ${theme.light_og},
    ${theme.pastel_og}
  );
`;

const ExperienceItem: React.FC<Props> = ({
  data,
  fixed_width,
  children,
  uistatus,
  ...rest
}: Props) => {
  const target = useRef(null);
  useLazyBg(target);
  let status = '';
  if (data.discount_info) status = data.discount_info;
  if (data.available === 0) status = 'SOLD OUT';
  else if (data.available === 2) status = 'Arriving soon';
  const current_date = moment()
    .tz('Asia/Seoul')
    .format('YYYYMMDD');
  return data.custom_url ? (
    <Wrapper
      has_description={!!data.description}
      cover_image={data.cover_image}
      {...rest}
    >
      <Link
        href={`/experience/detail/[slug]`}
        as={`/experience/detail/${data.custom_url}`}
      >
        <a
          target={uistatus.screenwidth === 'xs' ? '_self' : '_blank'}
          rel="noreferrer noopener"
          title={data.title}
        >
          <ListItemBox fixed_width={fixed_width} soldout={data.available === 0}>
            <div
              className="item-image"
              ref={target}
              data-src={data.cover_image}
            >
              {/* data.rating ? (
                <Rating
                  initialRating={data.rating}
                  readonly
                  style={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    textAlign: 'right',
                  }}
                />
              ) : null */}
              {data.new_flag_until >= current_date ? <New>N</New> : null}
            </div>
            <div className="item-text">
              <div className="title">{parse(data.title)}</div>
              <div className="price">
                {data.n_review ?
                  <div className="review">
                    <span className="rating_val">
                      {data.rating}
                    </span>
                    (<NumberFormat
                      value={data.n_review}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={''}
                    />)
                  </div> : null}
                {status ? <div className="status">{status}</div> : null}
                {data.booking_due === 0 ? <Lightning /> : null}
                {data.price > 0 ?
                <>
                  <span className="selling notranslate">
                    <Price
                      price={data.price}
                      from_currency={data.price_currency}
                      use_symbol
                      force_show
                      fixed_price_setting={data.fixed_price_setting}
                    />
                  </span>
                  {data.original_price ? (
                    <span className="original notranslate">
                      <Price
                        price={data.original_price}
                        from_currency={data.price_currency}
                        use_symbol={false}
                      />
                    </span>
                  ) : null}
                </>
                : null }
              </div>
              <div className="item-meta">
                <span className="meta meta_location">
                  <span className="sr-only">location </span>
                  {data.location}
                </span>
                {data.pageview ? (
                  <span className="meta meta_view">
                    <span className="sr-only">view </span>
                    {formatPageview(data.pageview, data.pageview_processed)}
                  </span>
                ) : null}
                {data.is_by_trazy === 1 ? (
                  <span className="made-by-trazy">MADE BY TRAZY</span>
                ) : null}
              </div>
            </div>
          </ListItemBox>
          {data.description ? (
            <div className="description">{data.description}</div>
          ) : null}
          {children}
        </a>
      </Link>
    </Wrapper>
  ) : null;
};

export default connect(state => ({
  uistatus: state.uistatus,
}))(ExperienceItem);
