import NumberFormatCustom from '/utils/numberFormat';

export default (pageview_label, pageview_processed) => {
  if (pageview_label >= 5) {
    if (!pageview_processed) {
      if (pageview_label < 50) {
        pageview_label = (pageview_label / 5) * 100 + 1000;
      } else {
        pageview_label = pageview_label + 2000;
      }
    }
    if (pageview_label >= 1000) {
      pageview_label = NumberFormatCustom(pageview_label / 1000, 1) + 'k';
    }
  }
  return pageview_label;
};
