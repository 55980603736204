import styled from 'styled-components';
import theme from '/style/theme';
import { transition } from '/style/mixin';

const Wrapper = styled.div`
  display: block;
  position: relative;
  z-index: 1;
  ${props =>
    props.fixed_width &&
    `
    width: 335px;
    .size-adjusted & {
      max-width: 335px;
      width: 100%;
    }
  `}
  height: 381px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${theme.line_gy};
  background-color: #fff;
  overflow: hidden;
  outline: 0;
  a {
    display: block;
  }
  ${transition()}
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  }
  a:visited & {
    border-color: ${theme.line_gy}; // for safari error
  }
  @media ${theme.sm} {
    ${props =>
      props.fixed_width &&
      `
    width: 345px;
  `}
  }
  @media ${theme.lg} {
    ${props =>
      props.fixed_width &&
      `
    width: 270px;
  `}
    height: 394px;
  }
  ${props =>
    props.soldout &&
    `
    background-color: ${theme.light_gy2};
    .item-text {
      .selling,
      .title {
        color: #aaa;
      }
    }
  `}
`;
export default ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};
